import React, { lazy } from 'react';

// react lazy pages

const Help = lazy(() => import('../pages/additional/Help'));
const About = lazy(() => import('../pages/additional/About'));
const MailUs = lazy(() => import('../pages/additional/MailUs'));
const VdsVps = lazy(() => import('../pages/additional/VdsVps'));
const Vpses = lazy(() => import('../pages/dashboard/vps/Vpses'));
const NftInfo = lazy(() => import('../pages/additional/NftInfo'));
const AllNews = lazy(() => import('../pages/additional/AllNews'));
const SEOInfo = lazy(() => import('../pages/additional/SEOInfo'));
const Types = lazy(() => import('../pages/dashboard/types/Types'));
const Users = lazy(() => import('../pages/dashboard/users/Users'));
const NewsItem = lazy(() => import('../pages/additional/NewsItem'));
const CrmPage = lazy(() => import('../pages/dashboard/crm/CrmPage'));
const RgbPage = lazy(() => import('../pages/dashboard/rgb/RgbPage'));
const VpsItem = lazy(() => import('../pages/dashboard/vps/VpsItem'));
const IpAddress = lazy(() => import('../pages/additional/IpAddress'));
const PriceList = lazy(() => import('../pages/additional/PriceList'));
const UserRegister = lazy(() => import('../pages/auth/UserRegister'));
const MyFiles = lazy(() => import('../pages/dashboard/files/MyFiles'));
const Article400 = lazy(() => import('../pages/additional/Article400'));
const SslContent = lazy(() => import('../pages/additional/SslContent'));
const VdsContent = lazy(() => import('../pages/additional/VdsContent'));
const DnssecInfo = lazy(() => import('../pages/additional/DnssecInfo'));
const Domains = lazy(() => import('../pages/dashboard/domains/Domains'));
const Tickets = lazy(() => import('../pages/dashboard/tickets/Tickets'));
const VpsModify = lazy(() => import('../pages/dashboard/vps/VpsModify'));
const AllContacts = lazy(() => import('../pages/additional/AllContacts'));
const WebnameDocs = lazy(() => import('../pages/additional/WebnameDocs'));
const PublicOffer = lazy(() => import('../pages/additional/PublicOffer'));
const RemindPassword = lazy(() => import('../pages/auth/RemindPassword'));
const UsersItem = lazy(() => import('../pages/dashboard/users/UsersItem'));
const Messages = lazy(() => import('../pages/dashboard/messages/Messages'));
const ListLots = lazy(() => import('../pages/dashboard/listlots/ListLots'));
const External = lazy(() => import('../pages/dashboard/external/External'));
const Articles = lazy(() => import('../pages/dashboard/articles/Articles'));
const FastServices = lazy(() => import('../pages/additional/FastServices'));
const HostingsList = lazy(() => import('../pages/additional/HostingsList'));
const MailDirector = lazy(() => import('../pages/additional/MailDirector'));
const TestServices = lazy(() => import('../pages/additional/TestServices'));
const Histories = lazy(() => import('../pages/dashboard/history/Histories'));
const MyHosting = lazy(() => import('../pages/dashboard/hostings/MyHosting'));
const DnssecContent = lazy(() => import('../pages/additional/DnssecContent'));
const DomainContent = lazy(() => import('../pages/additional/DomainContent'));
const Contracts = lazy(() => import('../pages/dashboard/contracts/Contracts'));
const Withdraws = lazy(() => import('../pages/dashboard/withdraws/Withdraws'));
const MoveDomain = lazy(() => import('../pages/dashboard/domains/MoveDomain'));
const CreateNewSsl = lazy(() => import('../pages/dashboard/ssl/CreateNewSsl'));
const MyContacts = lazy(() => import('../pages/dashboard/contacts/MyContacts'));
const Constructor = lazy(() => import('../constructor/components/Constructor'));
const HostingContent = lazy(() => import('../pages/additional/HostingContent'));
const SslCertificate = lazy(() => import('../pages/additional/SslCertificate'));
const AllDomainsList = lazy(() => import('../pages/additional/AllDomainsList'));
const DomainInfos = lazy(() => import('../pages/dashboard/domains/DomainInfos'));
const CreatedSites = lazy(() => import('../pages/dashboard/sites/CreatedSites'));
const ExtDomains = lazy(() => import('../pages/dashboard/extdomains/ExtDomains'));
const PaymentFail = lazy(() => import('../pages/dashboard/payments/PaymentFail'));
const MessageEdit = lazy(() => import('../pages/dashboard/messages/MessageEdit'));
const ListLotItem = lazy(() => import('../pages/dashboard/listlots/ListLotItem'));
const ArticleItem = lazy(() => import('../pages/dashboard/articles/ArticleItem'));
const ContactItem = lazy(() => import('../pages/dashboard/contacts/ContactItem'));
const ContactsForm = lazy(() => import('../pages/dashboard/contacts/ContactsForm'));
const ExternalItem = lazy(() => import('../pages/dashboard/external/ExternalItem'));
const WhmPackages = lazy(() => import('../pages/dashboard/whmpackages/WhmPackages'));
const WhmAccounts = lazy(() => import('../pages/dashboard/whmaccounts/WhmAccounts'));
const WithdrawItem = lazy(() => import('../pages/dashboard/withdraws/WithdrawItem'));
const ContractItem = lazy(() => import('../pages/dashboard/contracts/ContractItem'));
const OneDomainItem = lazy(() => import('../pages/dashboard/domains/OneDomainItem'));
const HistoriesItem = lazy(() => import('../pages/dashboard/history/HistoriesItem'));
const WhmCompanies = lazy(() => import('../pages/dashboard/whmcompany/WhmCompanies'));
const MyHostingItem = lazy(() => import('../pages/dashboard/hostings/MyHostingItem'));
const MyStatistics = lazy(() => import('../pages/dashboard/statistics/MyStatistics'));
const ContractsForm = lazy(() => import('../pages/dashboard/contracts/ContractsForm'));
const DomainTransfer = lazy(() => import('../pages/dashboard/domains/DomainTransfer'));
const ProfileSettings = lazy(() => import('../pages/dashboard/users/ProfileSettings'));
const CreatedSiteItem = lazy(() => import('../pages/dashboard/sites/CreatedSiteItem'));
const SiteConstructor = lazy(() => import('../pages/dashboard/sites/SiteConstructor'));
const RequestItem = lazy(() => import('../pages/dashboard/domainrequests/RequestItem'));
const Declorations = lazy(() => import('../pages/dashboard/declorations/Declorations'));
const ExtContracts = lazy(() => import('../pages/dashboard/extcontracts/ExtContracts'));
const ExtMoveDomain = lazy(() => import('../pages/dashboard/extdomains/ExtMoveDomain'));
const PaymentInvoice = lazy(() => import('../pages/dashboard/payments/PaymentInvoice'));
const PaymentSuccess = lazy(() => import('../pages/dashboard/payments/PaymentSuccess'));
const RedirectDomains = lazy(() => import('../pages/dashboard/domains/RedirectDomains'));
const ListDsecDomains = lazy(() => import('../pages/dashboard/domains/ListDsecDomains'));
const TransferDomains = lazy(() => import('../pages/dashboard/domains/TransferDomains'));
const ConstructorSites = lazy(() => import('../pages/dashboard/sites/ConstructorSites'));
const MySslCertificates = lazy(() => import('../pages/dashboard/ssl/MySslCertificates'));
const ExtDomainInfos = lazy(() => import('../pages/dashboard/extdomains/ExtDomainInfos'));
const PaymentServices = lazy(() => import('../pages/dashboard/payments/PaymentServices'));
const Notifications = lazy(() => import('../pages/dashboard/notifications/Notifications'));
const WhmPackageItem = lazy(() => import('../pages/dashboard/whmpackages/WhmPackageItem'));
const WhmAccountItem = lazy(() => import('../pages/dashboard/whmaccounts/WhmAccountItem'));
const CreateNameServer = lazy(() => import('../pages/dashboard/domains/CreateNameServer'));
const NewDomainRegister = lazy(() => import('../pages/dashboard/domains/NewDomainRegister'));
const ContractInvoices = lazy(() => import('../pages/dashboard/contracts/ContractInvoices'));
const InvoiceSources = lazy(() => import('../pages/dashboard/invoicesources/InvoiceSources'));
const DomainRequests = lazy(() => import('../pages/dashboard/domainrequests/DomainRequests'));
const ExtContractItem = lazy(() => import('../pages/dashboard/extcontracts/ExtContractItem'));
const TroubleTickets = lazy(() => import('../pages/dashboard/trouble-tickets/TroubleTickets'));
const ContractAutoRenew = lazy(() => import('../pages/dashboard/contracts/ContractAutoRenew'));
const MySslCertificateItem = lazy(() => import('../pages/dashboard/ssl/MySslCertificateItem'));
const ExtContractsForm = lazy(() => import('../pages/dashboard/extcontracts/ExtContractsForm'));
const FastServicesList = lazy(() => import('../pages/dashboard/fastservices/FastServicesList'));
const SearchingResultDomains = lazy(() => import('../pages/additional/SearchingResultDomains'));
const Restorehostings = lazy(() => import('../pages/dashboard/restorehostings/Restorehostings'));
const NotificationItem = lazy(() => import('../pages/dashboard/notifications/NotificationItem'));
const WhmAccountDisabled = lazy(() => import('../pages/dashboard/whmaccounts/WhmAccountDisabled'));
const NewTroubleTicket = lazy(() => import('../pages/dashboard/trouble-tickets/NewTroubleTicket'));
const DomainRequestItem = lazy(() => import('../pages/dashboard/domainrequests/DomainRequestItem'));
const PaymentServiceYandex = lazy(() => import('../pages/dashboard/payments/PaymentServiceYandex'));
const TroubleTicketItem = lazy(() => import('../pages/dashboard/trouble-tickets/TroubleTicketItem'));
const ContractInvoicesItem = lazy(() => import('../pages/dashboard/contracts/ContractInvoicesItem'));
const NewExtDomainRegister = lazy(() => import('../pages/dashboard/extdomains/NewExtDomainRegister'));
const RestorehostingItem = lazy(() => import('../pages/dashboard/restorehostings/RestorehostingItem'));
const FastServicesListItem = lazy(() => import('../pages/dashboard/fastservices/FastServicesListItem'));
const ContractInvoicesPostFix = lazy(() => import('../pages/dashboard/contracts/ContractInvoicesPostFix'));
const TroubleTicketItemForm = lazy(() => import('../pages/dashboard/trouble-tickets/TroubleTicketItemForm'));

export const staticRouters = [
    { path: 'help', element: <Help /> },
    { path: 'nft', element: <NftInfo /> },
    { path: 'ip', element: <IpAddress /> },
    { path: 'news', element: <AllNews /> },
    { path: 'mailus', element: <MailUs /> },
    { path: 'vps-vds', element: <VdsVps /> },
    { path: 'seo-for', element: <SEOInfo /> },
    { path: 'docs', element: <WebnameDocs /> },
    { path: 'articles/1', element: <About /> },
    { path: 'dnssec', element: <DnssecInfo /> },
    { path: 'news/:id', element: <NewsItem /> },
    { path: 'ssl', element: <SslCertificate /> },
    { path: 'www', element: <AllDomainsList /> },
    { path: 'ssl_nima', element: <SslContent /> },
    { path: 'hosting', element: <HostingsList /> },
    { path: 'price-list', element: <PriceList /> },
    { path: 'server_nima', element: <VdsContent /> },
    { path: 'articles/400', element: <Article400 /> },
    { path: 'constructor', element: <Constructor /> },
    { path: 'constructor/:id', element: <Constructor /> },
    { path: 'maildirect', element: <MailDirector /> },
    { path: 'public_offer', element: <PublicOffer /> },
    { path: 'domen_nima', element: <DomainContent /> },
    { path: 'dnssec_nima', element: <DnssecContent /> },
    { path: 'forgot-pass', element: <RemindPassword /> },
    { path: 'test-services', element: <TestServices /> },
    { path: 'fast-services', element: <FastServices /> },
    { path: 'hosting_nima', element: <HostingContent /> },
    { path: 'search', element: <SearchingResultDomains /> },
    { path: 'search/:id', element: <SearchingResultDomains /> },
    { path: 'articles/362/Contacts.html', element: <AllContacts /> },
]

export const loggedRouters = [
    {
        path: 'domains',
        index: <Domains />,
        childrens: [
            { childPath: ':id', element: <DomainInfos /> },
            { childPath: 'move', element: <MoveDomain /> },
            { childPath: '?form', element: <NewDomainRegister /> },
            { childPath: 'form', element: <NewDomainRegister /> },
            { childPath: 'domain/:id', element: <OneDomainItem /> },
        ]
    },
    {
        path: 'extdomains',
        index: <ExtDomains />,
        childrens: [
            { childPath: ':id', element: <ExtDomainInfos /> },
            { childPath: 'move', element: <ExtMoveDomain /> },
            { childPath: '?form', element: <NewExtDomainRegister /> },
            { childPath: 'form', element: <NewExtDomainRegister /> },
        ]
    },
    {
        path: 'contracts',
        index: <Contracts />,
        childrens: [
            { childPath: ':id', element: <ContractItem /> },
            { childPath: ':id?form', element: <ContractsForm /> },
            { childPath: 'invoices', element: <ContractInvoices /> },
            { childPath: 'autorenew/:id', element: <ContractAutoRenew /> },
            { childPath: 'invoices/view/:id', element: <ContractInvoicesItem /> }
        ]
    },
    {
        path: 'payments',
        index: <PaymentServices />,
        childrens: [
            { childPath: 'fail', element: <PaymentFail /> },
            { childPath: 'pay', element: <PaymentServices /> },
            { childPath: 'invoice', element: <PaymentInvoice /> },
            { childPath: 'success', element: <PaymentSuccess /> },
            { childPath: 'yandex', element: <PaymentServiceYandex /> }
        ]
    },
    {
        path: 'extcontracts',
        index: <ExtContracts />,
        childrens: [
            { childPath: ':id', element: <ExtContractItem /> },
            { childPath: 'ssl/:id', element: <CreateNewSsl /> },
            { childPath: ':id?form', element: <ExtContractsForm /> }
        ]
    },
    {
        path: 'contacts',
        index: <MyContacts />,
        childrens: [
            { childPath: ':id', element: <ContactItem /> },
            { childPath: ':id?form', element: <ContactsForm /> },
            { childPath: '?form', element: <UserRegister /> }
        ]
    },
    {
        path: 'whmaccounts',
        index: <WhmAccounts />,
        childrens: [
            { childPath: ':id', element: <WhmAccountItem /> },
            { childPath: 'disabled', element: <WhmAccountDisabled /> }
        ]
    },
    {
        path: 'users',
        index: <Users />,
        childrens: [
            { childPath: ':id', element: <UsersItem /> },
            { childPath: 'details', element: <ProfileSettings /> }
        ]
    },
    {
        path: 'messages',
        index: <Messages />,
        childrens: [
            { childPath: ':id', element: <Messages /> },
            { childPath: 'edit', element: <MessageEdit /> }
        ]
    },
    {
        path: 'invoices',
        index: <ContractInvoices />,
        childrens: [
            { childPath: ':id/:id', element: <ContractInvoicesPostFix /> },
            { childPath: 'view/:id', element: <ContractInvoicesItem /> }
        ]
    },
    {
        path: 'vpses',
        index: <Vpses />,
        childrens: [
            { childPath: ':id', element: <VpsItem /> },
            { childPath: 'modify/:id', element: <VpsModify /> }
        ]
    },
    {
        path: 'external',
        index: <External />,
        childrens: [
            { childPath: 'export', element: <External /> },
            { childPath: ':id', element: <ExternalItem /> }
        ]
    },
    {
        path: 'sites',
        index: <CreatedSites />,
        childrens: [
            { childPath: ':id', element: <CreatedSiteItem /> },
        ]
    },
    {
        path: 'trouble-tickets',
        index: <TroubleTickets />,
        childrens: [
            { childPath: 'form', element: <NewTroubleTicket /> },
            { childPath: ':id', element: <TroubleTicketItem /> },
            { childPath: ':id/form', element: <TroubleTicketItemForm /> },
        ]
    },
    {
        path: 'constructor-sites',
        index: <ConstructorSites />,
    },
    {
        path: 'fast-services-list',
        index: <FastServicesList />,
        childrens: [
            { childPath: ':id', element: <FastServicesListItem /> },
        ]
    },
    {
        path: 'withdraws',
        index: <Withdraws />,
        childrens: [
            { childPath: ':id', element: <WithdrawItem /> },
        ]
    },
    {
        path: 'invoicesources',
        index: <InvoiceSources />,
        childrens: [
            { childPath: 'export', element: <InvoiceSources /> },
        ]
    },
    {
        path: 'transfer',
        index: <TransferDomains />,
        childrens: [
            { childPath: 'out', element: <DomainTransfer /> }
        ]
    },
    {
        path: 'hostings',
        index: <MyHosting />,
        childrens: [
            { childPath: ':id', element: <MyHostingItem /> }
        ]
    },
    {
        path: 'domainrequests',
        index: <DomainRequests />,
        childrens: [
            { childPath: ':id', element: <DomainRequestItem /> }
        ]
    },
    {
        path: 'notices',
        index: <Notifications />,
        childrens: [
            { childPath: ':id', element: <NotificationItem /> }
        ]
    },
    {
        path: 'tickets',
        index: <Tickets />,
        childrens: [
            { childPath: ':id', element: <Tickets /> }
        ]
    },
    {
        path: 'certificates',
        index: <MySslCertificates />,
        childrens: [
            { childPath: ':id', element: <MySslCertificateItem /> }
        ]
    },
    {
        path: 'types',
        index: <Types />,
        childrens: [
            { childPath: ':id', element: <Types /> }
        ]
    },
    {
        path: 'whmpackages',
        index: <WhmPackages />,
        childrens: [
            { childPath: ':id', element: <WhmPackageItem /> }
        ]
    },
    {
        path: 'historys',
        index: <Histories />,
        childrens: [
            { childPath: ':id', element: <HistoriesItem /> }
        ]
    },
    {
        path: 'articles',
        index: <Articles />,
        childrens: [
            { childPath: ':id', element: <ArticleItem /> }
        ]
    },
    {
        path: 'listlots',
        index: <ListLots />,
        childrens: [
            { childPath: ':id', element: <ListLotItem /> }
        ]
    },
    {
        path: 'restorehostings',
        index: <Restorehostings />,
        childrens: [
            { childPath: ':id', element: <RestorehostingItem /> }
        ]
    },
    {
        path: 'rgb',
        index: <RgbPage />,
        childrens: []
    },
    {
        path: 'site',
        index: <SiteConstructor />,
        childrens: []
    },
    {
        path: 'nameservers',
        index: <CreateNameServer />,
        childrens: []
    },
    {
        path: 'listdsec',
        index: <ListDsecDomains />,
        childrens: []
    },
    {
        path: 'files',
        index: <MyFiles />,
        childrens: []
    },
    {
        path: 'overall',
        index: <MyStatistics />,
        childrens: []
    },
    {
        path: 'whmcompanys',
        index: <WhmCompanies />,
        childrens: []
    },
    {
        path: 'requests/:id',
        index: <RequestItem />,
        childrens: []
    },
    {
        path: 'declorations',
        index: <Declorations />,
        childrens: []
    },
    {
        path: 'crm',
        index: <CrmPage />,
        childrens: []
    },
    {
        path: 'redirect-domains',
        index: <RedirectDomains />,
        childrens: []
    },
];
